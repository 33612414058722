<template>
  <div class="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
    <div class="container">
      <div class="lg:w-5/6 mx-auto my-10 bg-white py-5 rounded-md shadow-sm">
        <div class="row">
          <div class="col-md-12 ml-5">
            <div class="row">
              <a href="/crowdhub-dashboard" class="col-md-3 text-dark flex items-center">
                <span
                  @click="back"
                  class="iconify"
                  data-icon="fa-solid:less-than"
                  data-inline="false"
                ></span>
                <p class="ml-3 mt-3">Back</p>
              </a>
              <div class="col-md-9 flex items-center">
                <img
                  src="../../../assets/icons/Oonpay logo.png"
                  height="80px"
                  width="80px"
                  alt=""
                  class="ml-5"
                />
                <p class="mt-3 font-bold">
                  Add Funding Raising Campaign Validators
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr class="new4" />

        <div class="row">
          <div class="col-md-8 flex justify-center">
            <div class="row px-5">
              <div class="col-md-6">
                <h5 class="text-center font-bold">
                  Support Maya Recovery Today
                </h5>
                <img src="https://dummyimage.com/600x400" alt="" />
              </div>
              <div class="col-md-6 py-14">
                <p><span class="font-bold">Campaign Period</span> (30 days)</p>
                <p><span class="font-bold">Started</span> August 9th 2021</p>
                <p class="font-bold text-oonpaysecondary">
                  Funding Goal $100000
                </p>
                <p class="font-bold text-oonpaysecondary">
                  Total Funds Raised $0
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="new4" />
        <div class="row">
          <div class="col-md-8">
            <p class="px-4">Current Validators</p>
            <div class="row">
              <div class="col-md-4">
                <div class="flex flex-col items-center">
                  <img
                    src="https://www.w3schools.com/howto/img_avatar2.png"
                    alt="Avatar"
                    class="avatar"
                  />
                  <span>Eugenia Ofosu</span>
                  <span>eu@gmail.com</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="flex flex-col items-center">
                  <img
                    src="https://www.w3schools.com/w3images/avatar6.png"
                    alt="Avatar"
                    class="avatar"
                  />
                  <span>Eric Asante</span>
                  <span>eric@gmail.com</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="flex flex-col items-center">
                  <img
                    src="https://www.w3schools.com/howto/img_avatar.png"
                    alt="Avatar"
                    class="avatar"
                  />
                  <span>Emmanuel Ofosu</span>
                  <span>emma@gmail.com</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <p class="text-primary">Validation Status</p>
          </div>
        </div>
        <hr class="new4" />
        <div class="row flex justify-center">
          <div class="col-md-8">
            <p class="text-center">Add Validator</p>
            <div class="form-group">
              <label for="validator">Validator Position/Status</label>
              <select class="form-control" name="validator">
                <option value="family">family</option>
              </select>
            </div>

            <div class="form-group row">
              <div class="col">
                <label class="text-muted" for="validator name"
                  >Validator Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="First name"
                />
              </div>
              <div class="col">
                <label class="text-muted" for="validator email"
                  >Validator Email</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last name"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label class="text-muted" for="validator email"
                  >Validator Email</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="qrobert@gmail.com"
                />
              </div>
              <div class="col">
                <label class="text-muted" for="validator email"
                  >Validator Business Line(optional)</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="555-555-5555"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="text-muted" for="validator">
                Validator Organisation
              </label>
              <input
                type="text"
                placeholder="Faith Temple Church"
                class="form-control"
              />
            </div>

            <div class="form-group row">
              <div class="col">
                <label class="text-muted" for="validator email"
                  >Validator City</label
                >
                <input type="text" class="form-control" placeholder="city" />
              </div>
              <div class="col">
                <label class="text-muted" for="validator email"
                  >Validator country</label
                >
                <select name="" class="form-control">
                  <option value="ghana">Ghana</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <textarea
                name="message"
                class="form-control"
                placeholder="message"
                rows="7"
              ></textarea>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="checkbox" name="include Campaign" id="" />
                  include Campaign Link
                </div>
                <div class="form-group">
                  <input type="checkbox" name="include Campaign" id="" />
                  invite To Download oonpay App
                </div>
                <div class="form-group">
                  <input type="checkbox" name="include Campaign" id="" />
                  Validator Can Add A Video/Text Messges
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="checkbox" name="include Campaign" id="" />
                  Send Email
                </div>
                <div class="form-group">
                  <input type="checkbox" name="include Campaign" id="" />
                  Send Text SMS Message
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-primary">Add More Validators</button>
              </div>
              <div class="col-md-6 flex justify-end">
                <button class="btn btn-primary">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push("/share-campaign");
    },
   
  },
};
</script>

<style  scoped>
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.iconify {
  font-size: 40px;
}
.preview {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

hr.new4 {
  border: 1px solid #ccc;
}

.btn-primary {
  background: #0c4369;
  border: #0c4369;
}
</style>